import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { generateEmptyTemplateRow } from "./helpers"
import { TemplateRow, TemplatesState } from "./types"
import { RootState } from "src/app/store"

const initialState: TemplatesState = {
  templates: [
    {
      name: "Default template",
      rows: [{ description: "", percentage: 0, fixedValue: 0, from: [], filter: [] }],
    },
  ],
  bindings: {},
}

const limitExpensesSlice = createSlice({
  name: "limitExpenses",
  initialState,
  reducers: {
    setExpenses(state, action: PayloadAction<TemplatesState>) {
      let expenses = action.payload
      state.templates = expenses.templates
      state.bindings = expenses.bindings
    },
    createTemplate(state, action: PayloadAction<{ name: string }>) {
      let { name } = action.payload
      state.templates.push({ name, rows: [generateEmptyTemplateRow(0)] })
    },
    addTemplateRow(state, action: PayloadAction<{ templateIndex: number; index: number }>) {
      let { templateIndex, index } = action.payload
      state.templates[templateIndex].rows.push(generateEmptyTemplateRow(index))
    },
    removeTemplateRow(state, action: PayloadAction<{ templateIndex: number; rowIndex: number }>) {
      let { templateIndex, rowIndex } = action.payload
      let template = state.templates[templateIndex]
      template.rows.forEach((row) => {
        let indexToRemove = -1
        row.from.forEach((linked, index) => {
          if (linked === rowIndex) {
            indexToRemove = index
          } else if (linked > rowIndex) {
            row.from[index]--
          }
        })
        if (indexToRemove > -1) {
          row.from.splice(indexToRemove, 1)
        }
        if (row.from.length === 0) {
          row.from.push(0)
        }
      })
      template.rows.splice(rowIndex, 1)
    },
    removeTemplate(state, action: PayloadAction<{ templateIndex: number }>) {
      let { templateIndex } = action.payload
      if (templateIndex === 0) return
      state.templates.splice(templateIndex, 1)
      for (let i in state.bindings) {
        if (state.bindings[i] === templateIndex) {
          delete state.bindings[i]
        } else if (state.bindings[i] > templateIndex) {
          state.bindings[i]--
        }
      }
    },
    updateTemplateRow<K extends keyof TemplateRow>(
      state: TemplatesState,
      action: PayloadAction<{ templateIndex: number; rowIndex: number; prop: K; value: TemplateRow[K] }>
    ) {
      let { templateIndex, rowIndex, prop, value } = action.payload
      let row = state.templates[templateIndex].rows[rowIndex]
      row[prop] = value
      if (prop === "filter") {
        if ((value as string).length) {
          row.from = []
        } else {
          row.from = [0]
        }
      }
    },
    assignTemplate(state, action: PayloadAction<{ selectedRowId: number; templateIndex: number }>) {
      let { selectedRowId, templateIndex } = action.payload
      state.bindings[selectedRowId] = templateIndex
    },
    changeTemlateName(state, action: PayloadAction<{ templateIndex: number; changedName: string }>) {
      let { templateIndex, changedName } = action.payload
      state.templates[templateIndex].name = changedName
    },
  },
})

export function selectTemplateBindings(state: RootState) {
  return state.limitExpenses.bindings
}

export function selectLimitExpenses(state: RootState) {
  return state.limitExpenses
}

export const {
  setExpenses,
  addTemplateRow,
  createTemplate,
  removeTemplateRow,
  removeTemplate,
  updateTemplateRow,
  assignTemplate,
  changeTemlateName,
} = limitExpensesSlice.actions

export default limitExpensesSlice.reducer
