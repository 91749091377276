import React, { useMemo } from "react"
import ListItem from "./ListItem"
import * as Styled from "./styled"
import { useSelector } from "react-redux"
import { selectTableData } from "../../tables/tablesSlice"
import { branchShouldBeVisible, isParent } from "../../tables/helpers"
import { selectProjectTreeData } from "./projectTreeSlice"
import { getActiveContentId } from "../../panels/helpers"
import { getColumnIndexById } from "../../tables/helpers"
import { ColumnIds } from "src/features/tables/enums"

export default function List() {
  let tableId = getActiveContentId()
  const { rows, filteredRowIndex } = useSelector((state) => selectTableData(state, tableId))
  const { expandedRowIds } = useSelector(selectProjectTreeData)

  const visibleRows = useMemo(() => {
    let res = []
    let startRowIndex = 0
    let lastRowIndex = rows.length - 1

    for (let rowIndex = startRowIndex; rowIndex <= lastRowIndex; rowIndex++) {
      let row = rows[rowIndex]
      res.push({ rowIndex, row })
      if (!expandedRowIds[row[getColumnIndexById(ColumnIds.ID)].v]) {
        let childIndex = rowIndex + 1
        while (childIndex < rows.length && rowIndex <= rows[childIndex][getColumnIndexById(ColumnIds.PARENT_INDEX)].v) {
          childIndex++
        }
        rowIndex = childIndex - 1
      }
    }

    return res
  }, [expandedRowIds, rows])

  return (
    <Styled.List>
      <ListItem value="Progect Name" show={true} tableId={tableId} rowIndex={-1}></ListItem>
      {visibleRows.map(({ row, rowIndex }) => (
        <ListItem
          key={row[getColumnIndexById(ColumnIds.ID)].v}
          rowIndex={rowIndex}
          rowId={row[getColumnIndexById(ColumnIds.ID)].v}
          expanded={expandedRowIds[row[getColumnIndexById(ColumnIds.ID)].v]}
          isParent={isParent(rowIndex)}
          value={row[getColumnIndexById(ColumnIds.ITEM_NAME)].v}
          show={branchShouldBeVisible(rows, expandedRowIds, row)}
          padding={(row[getColumnIndexById(ColumnIds.POSITION)].v.split(".").length - 1) * 12}
          selected={rowIndex === filteredRowIndex}
          tableId={tableId}
        />
      ))}
    </Styled.List>
  )
}
