import {
  arrowDownHandler,
  arrowLeftHandler,
  arrowRightHandler,
  arrowUpHandler,
  copyHandler,
  cutHandler,
  pasteHandler,
  redoHandler,
  undoHandler,
} from "./actions"

let isListenerAdded = false

export function addDocumentListeners() {
  if (!isListenerAdded) {
    document.addEventListener("keydown", globalKeyHandler)
    isListenerAdded = true
  }
}

export function removeDocumentListeners() {
  if (isListenerAdded) {
    document.removeEventListener("keydown", globalKeyHandler)
    isListenerAdded = false
  }
}

function globalKeyHandler(event) {
  if (["input", "textarea"].includes(event.target.type) || event.target.isContentEditable || event.repeat) return

  const { key, ctrlKey, metaKey, shiftKey } = event
  const isCtrlOrMetaPressed = ctrlKey || metaKey

  if (isCtrlOrMetaPressed) {
    handleCtrlMetaKey(key, shiftKey)

    return
  }
}

function handleCtrlMetaKey(key, shiftKey) {
  switch (key) {
    case "z":
      shiftKey ? redoHandler() : undoHandler()
      break
    case "c":
      copyHandler()
      break
    case "v":
      pasteHandler()
      break
    case "x":
      cutHandler()
      break
    default:
      break
  }
}

export function handleArrowKey(key) {
  switch (key) {
    case "ArrowUp":
      arrowUpHandler()
      break
    case "ArrowLeft":
      arrowLeftHandler()
      break
    case "ArrowRight":
      arrowRightHandler()
      break
    case "ArrowDown":
      arrowDownHandler()
      break
    default:
      break
  }
}

export function isArrowKey(key) {
  return ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(key)
}
