import { useDispatch, useSelector } from "react-redux"
import { configureStore } from "@reduxjs/toolkit"
import historyMiddleware from "./middlewares/historyMiddleware"
import tablesReducer from "../features/tables/tablesSlice"
import panelsReducer from "../features/panels/panelSlice"
import selectionSlice from "../features/selection/selectionSlice"
import limitExpensesReducer from "../features/limitExpenses/limitExpensesSlice"
import loaderSlice from "../features/loader/loaderSlice"
import projectTreeSlice from "../features/detailsPanel/projectTree/projectTreeSlice"
import tableMiddleware from "./middlewares/tableMiddleware"
import baseMiddleware from "./middlewares/baseMiddleware"
import pagesReducer from "../features/pages/pagesSlice"
import columnsMiddleware from "./middlewares/columnsMiddleware"
import projectsSlice from "../features/projects/projectsSlice"

const store = configureStore({
  reducer: {
    selection: selectionSlice,
    limitExpenses: limitExpensesReducer,
    loader: loaderSlice,
    projectTree: projectTreeSlice,
    tables: tablesReducer,
    panels: panelsReducer,
    pages: pagesReducer,
    projects: projectsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(baseMiddleware, historyMiddleware, tableMiddleware, columnsMiddleware),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()

export default store
