import React from "react"
import * as Styled from "./styled"
import DetailsCell from "./DetailsCell"
import { useSelector } from "react-redux"
import { selectTableData } from "../../tables/tablesSlice"
import { useMemo } from "react"
import { getColumnIndexById } from "../../tables/helpers"
import { ColumnIds } from "src/features/tables/enums"

export default function RowProps({ tableId }) {
  const { columns, rowProps, rows } = useSelector((state) => selectTableData(state, tableId))

  const cell = useMemo(() => {
    const selectedRow = rows.find((row) => rowProps[row[getColumnIndexById(ColumnIds.ID)].v].selected)
    const rowIndex = rows.indexOf(selectedRow)

    const res = []

    if (selectedRow) {
      for (let i = 0; i < columns.length; i++) {
        if (columns[i].show) {
          res.push(<DetailsCell key={i} title={selectedRow[i].v} rowIndex={rowIndex} columnIndex={i} />)
        }
      }
    }

    return res
  }, [columns, rowProps, rows])

  return (
    <Styled.Wrapper>
      <Styled.TitlesWrapper>
        {columns.map((column, index) => column.show && <div key={index}>{column.title}</div>)}
      </Styled.TitlesWrapper>
      <Styled.DetailsWrapper>{cell}</Styled.DetailsWrapper>
    </Styled.Wrapper>
  )
}
