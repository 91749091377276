import React, { useCallback, useEffect, useMemo, useState } from "react"
import * as Styled from "./styled"
import { ReactComponent as UpAndDown } from "../../../icons/up-and-down.svg"
import { ReactComponent as FilterArrow } from "../../../icons/filter-arrow.svg"
import { useDispatch, useSelector } from "react-redux"
import { createProject, fetchAllProjects, selectAllProjects, setActiveProjectId } from "../../projects/projectsSlice"
import { setPage } from "../pagesSlice"
import Popup from "../../../common/ui/Popup"
import * as API from "../../../app/api"
import { defaultColumns } from "../../tables/columns"
import { TABLE_TYPE } from "../../tables/constants"
import { changeLoaderVisibility } from "../../loader/loaderSlice"
import { Pages } from "../enums"

export default function Dashboard() {
  const unsortedProjects = useSelector(selectAllProjects)
  const dispatch = useDispatch()
  const [creating, setCreating] = useState(false)
  const [projectName, setProjectName] = useState("")
  const tableId = useMemo(() => localStorage.getItem("table_id"), [])
  const [sortProps, setSortProps] = useState({ by: "name", AZ: false, byDate: false })

  const goToHomePage = useCallback(() => {
    dispatch(setPage({ page: Pages.HOME }))
  }, [dispatch])

  const chooseProjectHandler = useCallback(
    (id) => {
      dispatch(setActiveProjectId({ id }))
      localStorage.setItem("table_id", id)
      goToHomePage()
    },
    [dispatch, goToHomePage]
  )

  function createNewProject() {
    let row = defaultColumns.map((column) => ({ ...column.defaultValue }))

    API.createNewProject({
      meta: {
        name: projectName,
      },
      estimates: {
        rows: [row],
        rowProps: {
          1: {
            expanded: true,
            selected: false,
            height: 20,
          },
        },
        columns: defaultColumns,
        customCategories: ["Material", "Labor", "Equipment"],
        filteredRowIndex: -1,
        selectedCell: {
          cellIndex: null,
          rowIndex: 0,
        },
        nextId: 2,
        type: TABLE_TYPE.ESTIMATES,
      },
      expenses: {
        templates: [
          {
            name: "Default template",
            rows: [
              {
                description: "",
                percentage: 0,
                fixedValue: 0,
                from: [],
                filter: [],
              },
            ],
          },
        ],
        bindings: {},
      },
    }).then((projectId) => {
      dispatch(createProject({ projectId, meta: { name: projectName } }))
      dispatch(setActiveProjectId({ id: projectId }))
      setCreating(false)
      dispatch(setPage({ page: Pages.HOME }))
    })
  }

  function createNewProjectOnEnter(e) {
    if (e.code === "Enter" && creating) {
      createNewProject()
    }
  }

  function logOut() {
    dispatch(changeLoaderVisibility({ show: true }))
    localStorage.removeItem("accessToken")
    localStorage.removeItem("table_id")
    window.location.reload()
  }

  const sortedProjects = useMemo(() => {
    let sorted = [...unsortedProjects.all]

    if (sortProps.by === "name") {
      sorted.sort((a, b) => {
        let comparison = a.meta.name.localeCompare(b.meta.name, undefined, { numeric: true })

        return sortProps.AZ ? comparison : -comparison
      })
    }

    return sorted
  }, [sortProps.AZ, sortProps.by, unsortedProjects])

  const projects = sortedProjects.map((project) => (
    <Styled.ListItem key={project.id} onClick={() => chooseProjectHandler(project.id)}>
      {project.meta.name}
    </Styled.ListItem>
  ))

  useEffect(() => {
    dispatch(fetchAllProjects())
    dispatch(changeLoaderVisibility({ show: false }))
  }, [dispatch])

  useEffect(() => {
    if (tableId) {
      chooseProjectHandler(localStorage.getItem("table_id"))
      localStorage.removeItem("table_id")
    }
  }, [tableId, chooseProjectHandler])

  return (
    <Styled.Wrapper onKeyDown={(e) => createNewProjectOnEnter(e)}>
      <Styled.ProjectsWrapper>
        <Styled.Header>
          <div>
            <h3>Project</h3>
            <span>Opened by you</span>
          </div>
          <button onClick={() => setCreating(true)}>+ Add new Project</button>
        </Styled.Header>
        <Styled.List>
          <Styled.ListHeader>
            <div onClick={() => setSortProps((prev) => ({ ...prev, AZ: !prev.AZ }))} className="cursor-pointer">
              <h4>Project Name</h4>
              {sortProps.byDate ? (
                <UpAndDown style={{ transition: "0.2s" }} />
              ) : (
                <FilterArrow
                  style={{ transform: sortProps.AZ ? "rotate(0deg)" : "rotate(180deg)", transition: "0.2s" }}
                />
              )}
            </div>
            <div>
              <span>Date Modified</span>
            </div>
          </Styled.ListHeader>
          {projects}
        </Styled.List>
      </Styled.ProjectsWrapper>
      <Styled.ProjectsWrapper>
        <Styled.Header>
          <div>
            <h3>Library</h3>
            <span>Shared with you</span>
          </div>
        </Styled.Header>
      </Styled.ProjectsWrapper>
      <button onClick={logOut}>Logout</button>
      {creating && (
        <Popup onClose={() => setCreating(false)}>
          <div className="popup">
            <input type="text" placeholder="Project name" onChange={(e) => setProjectName(e.target.value)} />
            <button onClick={createNewProject}>Create</button>
          </div>
        </Popup>
      )}
    </Styled.Wrapper>
  )
}
