import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Pages } from "./enums"
import { RootState } from "src/app/store"

const initialState = {
  currentPage: Pages.INITIAL,
}

const pagesSlice = createSlice({
  name: "pages",
  initialState: initialState,
  reducers: {
    setPage(state, action: PayloadAction<{ page: Pages }>) {
      const currentPage = action.payload.page
      state.currentPage = currentPage
    },
  },
})

export function selectPages(state: RootState) {
  return state.pages
}

export const { setPage } = pagesSlice.actions

export default pagesSlice.reducer
