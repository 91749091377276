import React, { useState, useEffect, useRef, useMemo, useCallback } from "react"
import * as Styled from "./styled"
import { useDispatch, useSelector } from "react-redux"
import { changeColumnVisibility, selectTableData, visibleAllColumns } from "../tables/tablesSlice"
import { ReactComponent as CheckAll } from "../../icons/checkAll.svg"
import { ColumnIds } from "../tables/enums"

export default function ColumnsPopup({ children, tableId }) {
  const tableData = useSelector((state) => selectTableData(state, tableId))
  const { columns } = tableData
  const dispatch = useDispatch()
  const [showPopup, setShowPopup] = useState(false)
  const popupRef = useRef(null)

  function openPopup(e) {
    setShowPopup(!showPopup)
    e.stopPropagation()
  }

  function selectAll(e) {
    dispatch(visibleAllColumns({ tableId }))
    e.stopPropagation()
  }

  const handleCheckColumn = useCallback(
    (id) => (event) => {
      dispatch(changeColumnVisibility({ id, tableId }))
      event.stopPropagation()
    },
    [dispatch, tableId]
  )

  const columnsList = useMemo(() => {
    const filteredColumns = columns.filter(
      (column) => column.id !== ColumnIds.ID && column.id !== ColumnIds.PARENT_INDEX
    )

    return filteredColumns.map((column, index) => (
      <Styled.ListItem onClick={handleCheckColumn(column.id)} key={column.title}>
        <Styled.Checkbox selected={column.show}>{column.show ? <CheckAll /> : null}</Styled.Checkbox>
        {column.title}
      </Styled.ListItem>
    ))
  }, [handleCheckColumn, columns])

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false)
      }
    }

    if (showPopup) {
      document.addEventListener("mousedown", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [showPopup])

  return (
    <Styled.Wrapper onClick={openPopup} ref={popupRef}>
      {children}
      {showPopup && (
        <Styled.Popup>
          {columnsList}
          <div onClick={selectAll}>Select All</div>
        </Styled.Popup>
      )}
    </Styled.Wrapper>
  )
}
