import Login from "./auth/Login"
import Register from "./auth/Register"
import InitialPage from "./auth/initialPage/InitialPage"
import Home from "./home"
import Dashboard from "./dashboard"
import { Pages } from "./enums"

export const routes = {
  [Pages.INITIAL]: <InitialPage />,
  [Pages.LOGIN]: <Login />,
  [Pages.REGISTER]: <Register />,
  [Pages.HOME]: <Home />,
  [Pages.DASHBOARD]: <Dashboard />,
}
