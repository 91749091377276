import { useEffect, useState } from "react"

export const useResize = <T>(memorizedCallback: () => T) => {
  const [value, setValue] = useState(memorizedCallback)

  useEffect(() => {
    const handleResize = () => {
      setValue(memorizedCallback())
    }

    handleResize()
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [memorizedCallback])

  return value
}
