import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ProjectTreeType } from "./types"
import { RootState } from "src/app/store"

const initialState: ProjectTreeType = {
  selectedRowIndex: -1,
  expandedRowIds: {},
}

const projectTreeSlice = createSlice({
  name: "projectTree",
  initialState: initialState,
  reducers: {
    toggleTreeExpandedStatus(state, action: PayloadAction<{ id: number }>) {
      const { id } = action.payload
      if (state.expandedRowIds[id]) {
        state.expandedRowIds[id] = false
      } else {
        state.expandedRowIds[id] = true
      }
    },
    setSelectedRowIndex(state, action: PayloadAction<{ rowIndex: number }>) {
      state.selectedRowIndex = action.payload.rowIndex
    },
  },
})

export function selectProjectTreeData(state: RootState) {
  return state.projectTree
}

export function selectSelectedRowIndex(state: RootState) {
  return state.projectTree.selectedRowIndex
}

export const { toggleTreeExpandedStatus, setSelectedRowIndex } = projectTreeSlice.actions

export default projectTreeSlice.reducer
