import React, { useId } from "react"
import * as Styled from "./styled"

export default function Checkbox({ title, isChecked, onCheck, disabled }) {
  const uniqueId = useId()

  return (
    <Styled.Wrapper>
      <input
        id={uniqueId}
        type="checkbox"
        checked={isChecked}
        onChange={(e) => onCheck(e.target.checked)}
        disabled={disabled}
      />
      <label htmlFor={uniqueId}>{title}</label>
    </Styled.Wrapper>
  )
}
