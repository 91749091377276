import styled from "styled-components"

export const Container = styled.div`
  width: 90vw;
  max-width: 1200px;
  height: 80vh;
  max-height: 90vh;
  overflow-x: auto;
  scrollbar-width: thin;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;

  .button {
    background-color: #fff;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    svg {
      cursor: pointer;
      transition: opacity 0.2s ease-in-out;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .list {
    flex-grow: 1;
    overflow: auto;
    max-height: 100%;
    min-width: fit-content;
    scrollbar-width: thin;
    scrollbar-color: #aaa transparent;

    ::-webkit-scrollbar-thumb {
      background: #aaa;
      border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #888;
    }
  }
`

export const Header = styled.div`
  height: 60px;
  display: flex;
  align-items: flex-end;
  width: ${({ width }) => `${width}px`};
  min-width: 100%;
  background-color: white;
  border-bottom: 1px solid silver;
  padding-bottom: 5px;
  flex-shrink: 0;
`
