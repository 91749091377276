import * as Styled from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/free-solid-svg-icons"

import Table from "../tables/Table"
import TableMenu from "../tableMenu/TableMenu"
import { addTab, closeTab, setActivePanel, setActiveTab } from "./panelSlice"
import { ReactComponent as TabsIcon } from "../../icons/tab.svg"
import { ReactComponent as RightPanelIconOpened } from "../../icons/opened-sidebar-icon.svg"
import { useDispatch, useSelector } from "react-redux"
import { CONTENT_TYPE } from "./constants"
import { ContextMenuContext } from "../../contexts/contextMenuContext"
import { useCallback, useContext } from "react"
import { CONTEXT_MENU_CONSTANTS } from "../../app/constants"
import DetailsPanel from "../detailsPanel"
import { useState, useRef, useEffect } from "react"
import LimitExpenses from "../limitExpenses/LimitExpenses"
import ToolTip from "../Tooltip"
import { selectAllProjects } from "../projects/projectsSlice"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"

export function Panel({ panelIndex, tabs, activeTab, panelsCount }) {
  const dispatch = useDispatch()
  const tableId = tabs[activeTab].contentId
  const { showContextMenu } = useContext(ContextMenuContext)
  const [extendedPanel, setExtendedPanel] = useState(false)
  const [showExpenses, setShowExpenses] = useState(false)
  const projects = useSelector((state) => selectAllProjects(state))
  const [childRef, setChildRef] = useState(null)
  const [visibleTabs, setVisibleTabs] = useState([])
  const [hiddenTabs, setHiddenTabs] = useState([])
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const tabsContainerRef = useRef(null)

  function switchTab(tabIndex) {
    dispatch(setActiveTab({ panelIndex, tabIndex }))
  }

  function closeTabHandler(e, tabIndex) {
    e.stopPropagation()
    dispatch(closeTab({ panelIndex, tabIndex }))
  }

  const contextMenuHandler = useCallback(
    (event, tableId) => {
      showContextMenu(CONTEXT_MENU_CONSTANTS.TABS, event, [
        () => {
          dispatch(
            addTab({ panelIndex: panelIndex === 0 ? 1 : 0, contentId: tableId, contentType: CONTENT_TYPE.PROJECT })
          )
        },
      ])
    },
    [dispatch, panelIndex, showContextMenu]
  )

  function activatePanel() {
    dispatch(setActivePanel({ panelIndex }))
  }

  function getProjectName(id) {
    const project = projects.all.find((project) => project.id === id)

    return project?.meta.name
  }

  useEffect(() => {
    const updateTabs = () => {
      if (!tabsContainerRef.current) return

      const containerWidth = tabsContainerRef.current.clientWidth
      let totalWidth = 0
      const newVisibleTabs = []
      const newHiddenTabs = []

      tabs.forEach((tab, index) => {
        totalWidth += 200
        if (totalWidth < containerWidth - 120) {
          newVisibleTabs.push(tab)
        } else {
          newHiddenTabs.push({ tab, index })
        }
      })

      setVisibleTabs(newVisibleTabs)
      setHiddenTabs(newHiddenTabs)
    }

    updateTabs()
    window.addEventListener("resize", updateTabs)

    return () => window.removeEventListener("resize", updateTabs)
  }, [extendedPanel, tabs])

  return (
    <Styled.Wrapper onContextMenu={activatePanel} onClickCapture={activatePanel} panelsCount={panelsCount}>
      <Styled.Main isExtended={extendedPanel}>
        <Styled.TabsWrapper ref={tabsContainerRef}>
          <ul>
            {visibleTabs.map((tab, index) => (
              <Styled.Tab
                key={tab.id}
                active={activeTab === index}
                onClick={() => switchTab(index)}
                onContextMenu={(event) => contextMenuHandler(event, tab.contentId)}
              >
                <span>Table {getProjectName(tab.contentId)}</span>
                <FontAwesomeIcon onClick={(e) => closeTabHandler(e, index)} icon={faXmark} />
              </Styled.Tab>
            ))}
            {hiddenTabs.length > 0 && (
              <Styled.DropdownContainer>
                <Styled.DropdownButton onClick={() => setDropdownOpen(!dropdownOpen)}>
                  More <FontAwesomeIcon isLine={false} icon={faChevronDown} size="xs" />
                </Styled.DropdownButton>

                {dropdownOpen && (
                  <Styled.DropdownMenu>
                    {hiddenTabs.map(({ tab, index }) => (
                      <Styled.DropdownItem
                        key={tab.id}
                        onClick={() => {
                          switchTab(index)
                          setDropdownOpen(false)
                        }}
                      >
                        Table {getProjectName(tab.contentId)}
                      </Styled.DropdownItem>
                    ))}
                  </Styled.DropdownMenu>
                )}
              </Styled.DropdownContainer>
            )}
          </ul>

          {extendedPanel ? (
            <ToolTip text="Hide Details" position={"left"}>
              <RightPanelIconOpened onClick={() => setExtendedPanel(!extendedPanel)} />
            </ToolTip>
          ) : (
            <ToolTip text="Show Details" position={"left"}>
              <TabsIcon onClick={() => setExtendedPanel(!extendedPanel)} />
            </ToolTip>
          )}
        </Styled.TabsWrapper>
        <TableMenu tableId={tableId} />
        <Table tableId={tableId} panelsCount={panelsCount} showExpenses={showExpenses} setChildRef={setChildRef} />
        <LimitExpenses tableId={tableId} show={showExpenses} setShow={setShowExpenses} extendedPanel={extendedPanel} />
      </Styled.Main>
      {extendedPanel && <DetailsPanel tableId={tableId} panelsCount={panelsCount} tableRef={childRef} />}
    </Styled.Wrapper>
  )
}
