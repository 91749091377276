import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  createSingleRowProps,
  extractCategoryName,
  findNextSiblingsIndex,
  generateDescendantIndexes,
  generateInitialRowProps,
  increaseParentIndexes,
} from "./helpers"
import { EXTRA_UI_COLUMNS } from "./constants"
import { updateTableWithFormulas } from "./formulas"
import { getActiveContentId } from "../panels/helpers"
import { getColumnIndexById } from "./helpers"
import { defaultColumns as columns } from "./columns"
import { CellValue, Column, Row, RowProps, TablesState, TableState } from "./types"
import { RootState } from "src/app/store"
import { ColumnIds, AddRowOption } from "./enums"

const initialState: TablesState = {}

function createEmptyRow(id: number, columns: Array<Column>) {
  let rows = columns.map((column) => ({ ...column.defaultValue }))
  let idIndex = columns.findIndex((column) => column.id === ColumnIds.ID)
  rows[idIndex].v = id

  return rows
}

const tableSlice = createSlice({
  name: "tables",
  initialState,
  reducers: {
    createTable(state, action: PayloadAction<{ tableId: string; data: TableState }>) {
      let { tableId, data } = action.payload
      state[tableId] = data

      if (!data.rows.length) {
        state[tableId].columns = data.columns.length ? data.columns : columns
        state[tableId].rows = [createEmptyRow(-1, state[tableId].columns)]
        state[tableId].rowProps = { "-1": createSingleRowProps() }
      } else if (!data.columns.length) {
        state[tableId].columns = columns
      }
      updateTableWithFormulas(state[tableId])
    },
    setTable(state, action: PayloadAction<{ tableId: string; rows: Row[] }>) {
      let { tableId, rows } = action.payload
      if (!state[tableId]) throw Error(`table with id ${tableId} does not exist!`)
      state[tableId].rowProps = generateInitialRowProps(rows.length) as RowProps
      state[tableId].nextId = rows.length + 1
      state[tableId].rows = rows
    },
    setColumns(state, action: PayloadAction<{ tableId: string; columns: Column[] }>) {
      let { tableId, columns } = action.payload
      state[tableId].columns = columns
    },

    setFilteredRowIndex(state, action: PayloadAction<{ tableId: string; rowIndex: number }>) {
      let { tableId, rowIndex } = action.payload
      state[tableId].filteredRowIndex = rowIndex
    },
    addRow(state, action: PayloadAction<{ index: number; option: AddRowOption; _panels: any }>) {
      let { index, option, _panels } = action.payload
      let tableId = getActiveContentId(_panels)

      let { rows, rowProps, columns } = state[tableId]
      let rowIndex
      const newRow = createEmptyRow(state[tableId].nextId, columns)
      rowProps[state[tableId].nextId] = createSingleRowProps()
      state[tableId].nextId++

      switch (option) {
        case AddRowOption.CHILD:
          newRow[getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v = index
          rowIndex = findNextSiblingsIndex(rows, index, columns)
          increaseParentIndexes(rows, index, rowIndex + 1, undefined, columns)
          break
        case AddRowOption.SIBLING_BELOW:
          newRow[getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v =
            rows[index][getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v
          rowIndex = findNextSiblingsIndex(rows, index, columns)
          increaseParentIndexes(rows, index, rowIndex + 1, undefined, columns)
          break
        case AddRowOption.SIBLING_ABOVE:
          newRow[getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v =
            rows[index][getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v
          rowIndex = index
          increaseParentIndexes(
            rows,
            rows[index][getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v,
            rowIndex + 1,
            undefined,
            columns
          )
          break
        default:
          return
      }
      rows.splice(rowIndex, 0, newRow)
      updateTableWithFormulas(state[tableId])
    },
    replaceRow(state, action: PayloadAction<{ removeIndexes: Array<number>; rows: Array<Row>; _panels: any }>) {
      let { removeIndexes, rows = [], _panels } = action.payload
      rows = structuredClone(rows)
      let tableId = getActiveContentId(_panels)
      const columns = state[tableId].columns

      if (rows.length === 0) {
        // replace rows with single empty row
        const newEmptyRow = createEmptyRow(state[tableId].nextId, state[tableId].columns)
        state[tableId].rowProps[state[tableId].nextId] = createSingleRowProps()
        state[tableId].nextId++
        state[tableId].rows.splice(removeIndexes[0], 1, newEmptyRow)
      } else {
        let pasteIndex = removeIndexes[0]
        let parentIndex = state[tableId].rows[removeIndexes[0]][getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v!
        let levelsToIndexes = { [(rows[0][0].v as string).split(".").length]: parentIndex }
        rows.forEach((row: Row, index: number) => {
          // save mapping between levels and parent indexes
          // assign new parent index to each element by its level
          let level = (row[0].v as string).split(".").length
          if (levelsToIndexes[level] != null) {
            row.unshift({ v: levelsToIndexes[level], e: 0 })
            levelsToIndexes[level + 1] = pasteIndex + index
          } else {
            if (level < (rows[0][2].v as string).split(".").length) {
              row.unshift({ v: parentIndex, e: 0 })
            } else {
              row.unshift({ v: pasteIndex + index - 1, e: 0 })
            }
          }
          // add id and update rowprops
          row.unshift({ v: state[tableId].nextId, e: 0 })
          state[tableId].rowProps[state[tableId].nextId] = createSingleRowProps()
          state[tableId].nextId++
        })
        // replace selected rows with new rows and update parent indexes of rest
        state[tableId].rows.splice(removeIndexes[0], removeIndexes.length, ...rows)
        let deletedCount = rows.length - removeIndexes.length
        for (let i = removeIndexes[0] + rows.length; i < state[tableId].rows.length; i++) {
          if (state[tableId].rows[i][getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v! > parentIndex) {
            (state[tableId].rows[i][getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v as number) += deletedCount
          }
        }
      }
      updateTableWithFormulas(state[tableId])
    },
    removeRow(state, action: PayloadAction<{ index: number; _panels: any }>) {
      let { index, _panels } = action.payload
      let tableId = getActiveContentId(_panels)
      let rows = state[tableId].rows
      let columns = state[tableId].columns
      let removeCount = 1

      for (let i = index + 1; i < rows.length; i++) {
        if (
          (rows[i][getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v as number) >= index &&
          (rows[i][getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v as number) < index + removeCount
        ) {
          removeCount++
        } else if ((rows[i][getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v! as number) > index) {
          (rows[i][getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v as number)! -= removeCount
        }
      }
      rows.splice(index, removeCount)

      if (rows.length === 0) {
        rows.push(createEmptyRow(1, columns))
      }

      updateTableWithFormulas(state[tableId])
    },
    resizeColumn(state, action: PayloadAction<{ tableId: string; index: number; size: number }>) {
      const { tableId, index, size } = action.payload
      state[tableId].columns[index].width = size
    },
    toggleRowExpandedStatus(state, action: PayloadAction<{ id: number; _panels: any }>) {
      const { id, _panels } = action.payload
      let tableId = getActiveContentId(_panels)

      const { rowProps } = state[tableId]

      rowProps[id].expanded = !rowProps[id].expanded
    },
    updateCell(
      state,
      action: PayloadAction<{ rowIndex: number; columnIndex: number; value: CellValue; _panels: any }>
    ) {
      const { rowIndex, columnIndex, value, _panels } = action.payload
      let tableId = getActiveContentId(_panels)
      const { rows, columns } = state[tableId]

      let cellData = null
      let unitCostIndex = getColumnIndexById(ColumnIds.UNIT_COST, columns)
      if (columns[columnIndex].id.includes("_unit")) {
        let categoryIndex = getColumnIndexById(ColumnIds.CATEGORY, columns)
        cellData = rows[rowIndex][unitCostIndex]
        rows[rowIndex][categoryIndex].v = extractCategoryName(columns[columnIndex].id)
      } else {
        cellData = rows[rowIndex][columnIndex]
      }

      if (value === cellData.v) {
        return
      }
      cellData.v = value
      cellData.e = 1
      if (columns[columnIndex].type !== "number" && columnIndex !== getColumnIndexById(ColumnIds.CATEGORY, columns))
        return
      updateTableWithFormulas(state[tableId], { rowIndex, columnIndex })
    },

    updateRowHeight(state, action: PayloadAction<{ tableId: string; rowId: number; value: number }>) {
      const { tableId, rowId, value } = action.payload
      const { rowProps } = state[tableId]

      rowProps[rowId].height = Number(value)
    },
    setTableState(state, action: PayloadAction<{ tableId: string; nextState: TableState }>) {
      const rows = action.payload.nextState.rows
      let tableId = action.payload.tableId
      state[tableId].rows = [...rows]
    },
    setNewCategories(state, action: PayloadAction<{ newCategory: string; tableId: string }>) {
      const { newCategory, tableId } = action.payload
      const columns = state[tableId].columns
      const unitCostIndex = getColumnIndexById(ColumnIds.UNIT_COST, columns)
      state[tableId].customCategories.push(newCategory)

      const newUnitColumn = {
        ...state[tableId].columns[unitCostIndex],
        id: `category_${newCategory.toUpperCase()}_unit`,
        title: `${newCategory}  Unit Cost`,
      }
      const newTotalColumn = {
        ...state[tableId].columns[unitCostIndex],
        id: `category_${newCategory.toUpperCase()}_unit`,
        title: `${newCategory} Total Cost`,
      }

      state[tableId].columns.splice(unitCostIndex, 0, newUnitColumn, newTotalColumn)

      state[tableId].rows.forEach((row) => {
        row.splice(unitCostIndex, 0, { v: null, e: 0 }, { v: null, e: 0 })
      })

      updateTableWithFormulas(state[tableId])
    },
    outdentRows(state, action: PayloadAction<{ rowIndex: number; isLast: boolean; _panels: any }>) {
      const { rowIndex, isLast, _panels } = action.payload
      let tableId = getActiveContentId(_panels)
      const columns = state[tableId].columns

      let rows = state[tableId].rows
      if (rows[rowIndex][getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v === -1) {
        return
      }
      let nextIndex = rowIndex + 1

      while (
        nextIndex < rows.length &&
        rows[nextIndex][getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v! >=
          rows[rowIndex][getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v!
      ) {
        if (
          rows[nextIndex][getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v ===
          rows[rowIndex][getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v
        ) {
          rows[nextIndex][getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v = rowIndex
        }
        nextIndex++
      }
      rows[rowIndex][getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v =
        rows[rows[rowIndex][getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v! as number][
          getColumnIndexById(ColumnIds.PARENT_INDEX, columns)
        ].v
      isLast && updateTableWithFormulas(state[tableId])
    },
    indentRows(state, action: PayloadAction<{ rowIndex: number; isLast: boolean; _panels: any }>) {
      const { rowIndex, isLast, _panels } = action.payload
      let tableId = getActiveContentId(_panels)
      const columns = state[tableId].columns

      let rows = state[tableId].rows
      let parentIndex = (rows[rowIndex][getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v as number)!

      for (let i = rowIndex - 1; i > parentIndex; i--) {
        if (parentIndex === rows[i][getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v) {
          state[tableId].rows[rowIndex][getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v = i
          break
        }
      }
      isLast && updateTableWithFormulas(state[tableId])
    },
    upRows(state, action: PayloadAction<{ rowIndex: number; _panels: any }>) {
      let { rowIndex, _panels } = action.payload
      if (rowIndex === 0) return

      let tableId = getActiveContentId(_panels)
      const columns = state[tableId].columns
      let rows = state[tableId].rows
      let parentIndex = rows[rowIndex][getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v

      let moveIndex = rowIndex

      for (
        let i = rowIndex - 1;
        i > (rows[rowIndex][getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v as number)!;
        i--
      ) {
        if (rows[i][getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v === parentIndex) {
          moveIndex = i
          break
        }
      }

      if (moveIndex === rowIndex) return

      // collect copied row and its descendant
      let rowsToMove = [rows[rowIndex]]
      let iterator = generateDescendantIndexes(rowIndex, rows, columns)
      for (let index of iterator) {
        rowsToMove.push(rows[index])
        ;(rows[index][getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v as number)! -= rowIndex - moveIndex
      }

      // update parents of previous neighbours children
      iterator = generateDescendantIndexes(moveIndex, rows, columns)
      for (let index of iterator) {
        (rows[index][getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v as number) += rowsToMove.length
      }
      rows.splice(rowIndex, rowsToMove.length)
      rows.splice(moveIndex, 0, ...rowsToMove)

      updateTableWithFormulas(state[tableId])
    },
    downRows(state, action: PayloadAction<{ rowIndex: number; _panels: any }>) {
      let { rowIndex, _panels } = action.payload

      let tableId = getActiveContentId(_panels)
      const columns = state[tableId].columns
      let rows = state[tableId].rows
      let parentIndex = rows[rowIndex][getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v

      let moveIndex = rowIndex

      for (let i = rowIndex + 1; i < rows.length; i++) {
        if (rows[i][getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v === parentIndex) {
          moveIndex = i
          break
        }
      }
      if (moveIndex === rowIndex) return

      let rowsToMoveDown = [rows[rowIndex]]
      let rowsToUp = [rows[moveIndex]]

      let iterator = generateDescendantIndexes(rowIndex, rows, columns)
      let iterator1 = generateDescendantIndexes(moveIndex, rows, columns)
      let it = generateDescendantIndexes(rowIndex, rows, columns)

      for (let index of iterator) {
        rowsToMoveDown.push(rows[index])
      }

      for (let index of iterator1) {
        rowsToUp.push(rows[index])
        ;(rows[index][getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v as number)! -= rowsToMoveDown.length
      }

      for (let index of it) {
        (rows[index][getColumnIndexById(ColumnIds.PARENT_INDEX, columns)].v as number) += rowsToUp.length
      }
      rows.splice(moveIndex, rowsToUp.length)
      rows.splice(rowIndex, 0, ...rowsToUp)

      updateTableWithFormulas(state[tableId])
    },
    changeColumnVisibility(state, action: PayloadAction<{ id: string; tableId: string }>) {
      const { id, tableId } = action.payload
      const columns = state[tableId].columns

      columns.forEach((column) => {
        if (column.id === id) {
          column.show = !column.show
        }
      })
    },
    visibleAllColumns(state, action: PayloadAction<{ tableId: string }>) {
      const { tableId } = action.payload
      const columns = state[tableId].columns

      columns.forEach((column) => {
        if (column.id === ColumnIds.ID || column.id === ColumnIds.PARENT_INDEX) {
          column.show = false
        } else {
          column.show = true
        }
      })
    },
    resetColumnVisibility(state, action: PayloadAction<{ tableId: string }>) {
      const { tableId } = action.payload
      const columns = state[tableId].columns

      columns.forEach((column) => (column.show = false))
    },
    selectRow(
      state,
      action: PayloadAction<{ rowIndex: number; _panels: any; withChildren: boolean; combine: boolean }>
    ) {
      const { rowIndex, _panels, withChildren, combine } = action.payload
      let tableId = getActiveContentId(_panels)
      const columns = state[tableId].columns
      // select current row
      if (rowIndex !== -1) {
        let rowId = state[tableId].rows[rowIndex][getColumnIndexById(ColumnIds.ID, columns)].v!
        state[tableId].rowProps[rowId].selected = true
      }
      // select descendants
      let iterator = generateDescendantIndexes(rowIndex, state[tableId].rows, state[tableId].columns)
      for (let index of iterator) {
        let childId = state[tableId].rows[index][getColumnIndexById(ColumnIds.ID, columns)].v!
        state[tableId].rowProps[childId].selected = withChildren
      }
      // select with shiftkey
      if (combine) {
        let select = false
        for (let i = 0; i < rowIndex; i++) {
          let childId = state[tableId].rows[i][getColumnIndexById(ColumnIds.ID, columns)].v!
          if (state[tableId].rowProps[childId].selected) {
            select = true
          } else if (select) {
            state[tableId].rowProps[childId].selected = true
          }
        }

        select = false
        for (let i = state[tableId].rows.length - 1; i > rowIndex; i--) {
          let childId = state[tableId].rows[i][getColumnIndexById(ColumnIds.ID, columns)].v!
          if (state[tableId].rowProps[childId].selected) {
            select = true
          } else if (select) {
            state[tableId].rowProps[childId].selected = true
          }
        }
      }
    },
    unselectRow(state, action: PayloadAction<{ rowIndex: number; _panels: any }>) {
      const { rowIndex, _panels } = action.payload
      let tableId = getActiveContentId(_panels)
      const columns = state[tableId].columns
      if (rowIndex !== -1) {
        let rowId = state[tableId].rows[rowIndex][getColumnIndexById(ColumnIds.ID, columns)].v!
        state[tableId].rowProps[rowId].selected = false
      }
      // unselect children
      let iterator = generateDescendantIndexes(rowIndex, state[tableId].rows, state[tableId].columns)
      for (let index of iterator) {
        let childId = state[tableId].rows[index][getColumnIndexById(ColumnIds.ID, columns)].v!
        state[tableId].rowProps[childId].selected = false
      }
    },
    clearRowSelection(state, action: PayloadAction<{ _panels: any }>) {
      const { _panels } = action.payload
      let tableId = getActiveContentId(_panels)
      Object.values(state[tableId].rowProps).forEach((prop) => {
        if (prop.selected) {
          prop.selected = false
        }
      })
    },
    selectCell(state, action: PayloadAction<{ rowIndex: number; cellIndex: number; tableId: string }>) {
      const { rowIndex, cellIndex, tableId } = action.payload
      state[tableId].selectedCell.rowIndex = rowIndex
      state[tableId].selectedCell.cellIndex = cellIndex
    },
    unselectAllCells(state, action: PayloadAction<{ _panels: any }>) {
      const { _panels } = action.payload
      let activeTabs = _panels.activeTabs
      activeTabs.forEach((tabIndex: number, panelIndex: number) => {
        if (tabIndex != null) {
          let tableId = _panels.tabs[panelIndex][tabIndex].contentId
          state[tableId].selectedCell = { cellIndex: null, rowIndex: null }
        }
      })
    },
    changeColumnWidth(state, action: PayloadAction<{ newWidth: number; cellIndex: number; tableId: string }>) {
      const { newWidth, cellIndex, tableId } = action.payload
      state[tableId].columns[cellIndex].width = newWidth
    },

    condenseAllRows(state, action: PayloadAction<{ _panels: any }>) {
      const { _panels } = action.payload
      let tableId = getActiveContentId(_panels)
      const columns = state[tableId].columns

      const { rows, rowProps } = state[tableId]
      rows.forEach((row) => {
        rowProps[row[getColumnIndexById(ColumnIds.ID, columns)].v!].expanded = false
      })
    },
    expandAllRows(state, action: PayloadAction<{ _panels: any }>) {
      const { _panels } = action.payload
      let tableId = getActiveContentId(_panels)
      const columns = state[tableId].columns

      const { rows, rowProps } = state[tableId]
      rows.forEach((row) => {
        rowProps[row[getColumnIndexById(ColumnIds.ID, columns)].v!].expanded = true
      })
    },
    expendByLevels(state, action: PayloadAction<{ level: number; _panels: any }>) {
      const { level, _panels } = action.payload
      let tableId = getActiveContentId(_panels)
      const columns = state[tableId].columns

      const { rows, rowProps } = state[tableId]
      rows.forEach((row) => {
        let dots = (row[getColumnIndexById(ColumnIds.POSITION, columns)].v as string).split(".")
        rowProps[row[getColumnIndexById(ColumnIds.ID, columns)].v!].expanded = dots.length <= level
      })
    },
  },
})

export function selectTableData(state: RootState, tableId: string) {
  return state.tables[tableId]
}

export function selectTableRows(state: RootState, tableId: string) {
  return state.tables[tableId].rows
}

export function selectSelectedCell(state: RootState, tableId: string) {
  return state.tables[tableId].selectedCell
}

export function selectFilteredRowIndex(state: RootState, tableId: string) {
  return state.tables[tableId].filteredRowIndex
}

export function selectTableColumns(state: RootState) {
  let tableId = getActiveContentId()

  return state.tables[tableId].columns
}

export function selectCellsCountInRow(state: RootState, tableId: string) {
  return state.tables[tableId].columns.filter((column: Column) => column.show).length + EXTRA_UI_COLUMNS
}

export function selectCustomCategories(state: RootState, tableId: string) {
  return state.tables[tableId].customCategories
}

export const {
  updateCell,
  setTable,
  replaceRow,
  addRow,
  removeRow,
  resizeColumn,
  toggleRowExpandedStatus,
  setTableState,
  setColumns,
  setFilteredRowIndex,
  outdentRows,
  indentRows,
  changeColumnVisibility,
  resetColumnVisibility,
  createTable,
  selectRow,
  unselectRow,
  selectCell,
  unselectAllCells,
  clearRowSelection,
  upRows,
  downRows,
  updateRowHeight,
  visibleAllColumns,
  changeColumnWidth,
  condenseAllRows,
  expandAllRows,
  expendByLevels,
  setNewCategories,
} = tableSlice.actions

export default tableSlice.reducer
