import { useRef, useState, useMemo, useCallback } from "react"
import * as Styled from "./styled"
import ToolTip from "../Tooltip/index"
import MenuButton from "./MenuButton/index"
import {
  copyHandler,
  pasteHandler,
  redoHandler,
  undoHandler,
  outdentHandler,
  indentHandler,
  upRowHandler,
  addChildRowHandler,
  addSiblingRowHandler,
  downRowHandler,
  cutHandler,
} from "../../app/actions"
import { getDataFromExcel } from "../../app/api"
import { selectTableData, setTable, updateRowHeight } from "../tables/tablesSlice"
import { useDispatch, useSelector } from "react-redux"
import { changeLoaderVisibility } from "../loader/loaderSlice"
import { getColumnIndexById } from "../tables/helpers"
import { ReactComponent as AddItem } from "../../icons/add-item.svg"
import { ReactComponent as AddSubItem } from "../../icons/add-subitem.svg"
import { ReactComponent as Copy } from "../../icons/copy.svg"
import { ReactComponent as Paste } from "../../icons/paste.svg"
import { ReactComponent as Cut } from "../../icons/cut.svg"
import { ReactComponent as ItemUp } from "../../icons/arrow-up.svg"
import { ReactComponent as ItemDown } from "../../icons/arrow-down.svg"
import { ReactComponent as Outdent } from "../../icons/outdent.svg"
import { ReactComponent as Indent } from "../../icons/indent.svg"
import { ReactComponent as Undo } from "../../icons/undo.svg"
import { ReactComponent as Redo } from "../../icons/redo.svg"
import { ReactComponent as Columns } from "../../icons/Columns.svg"
import { ReactComponent as Import } from "../../icons/import.svg"
import { ReactComponent as Export } from "../../icons/export.svg"
import { ReactComponent as RowHeightLogo } from "../../icons/row-height-logo.svg"
import { ReactComponent as LevelIcon } from "../../icons/level-icon.svg"
import { ReactComponent as CategoryIcon } from "../../icons/category-icon.svg"

import { getActiveContentId } from "../panels/helpers"
import ClickAndChange from "../clickAndChange"
import ColumnsPopup from "../columnsPopup"
import Popup from "../../common/ui/Popup"
import ExportTable from "../tables/ExportTable"
import Levels from "./levels"
import SelectCategory from "./SelectCategory"
import { ColumnIds } from "../tables/enums"

export default function TableMenu({ tableId }) {
  const inputRef = useRef()
  const dispatch = useDispatch()
  const { rowProps, rows } = useSelector((state) => selectTableData(state, tableId))
  const [exportDataVisibility, setExportDataVisibility] = useState(false)
  const [showLevel, setShowLevel] = useState(false)
  const [showCategories, setShowCategories] = useState(false)

  const clickInput = useCallback(() => inputRef.current?.click(), [])
  const toggleCategories = useCallback(() => setShowCategories(true), [])
  const toggleLevel = useCallback(() => setShowLevel((prev) => !prev), [])

  const changeHeight = useCallback(
    (value) => {
      const selectedRows = rows.filter((row) => rowProps[row[getColumnIndexById(ColumnIds.ID)].v]?.selected)
      if (selectedRows.length === 0) {
        alert("select Row first")

        return
      }

      selectedRows.forEach((row) => {
        dispatch(updateRowHeight({ tableId, rowId: row[getColumnIndexById(ColumnIds.ID)].v, value }))
      })
    },
    [dispatch, rows, rowProps, tableId]
  )

  const countLevels = useMemo(() => {
    let levels = 0

    rows.forEach((row) => {
      let position = row[getColumnIndexById(ColumnIds.POSITION)].v.split(".")
      if (position.length > levels) {
        levels = position.length
      }
    })

    return levels
  }, [rows])

  async function uploadFile(event) {
    dispatch(changeLoaderVisibility({ show: true }))
    const rows = await getDataFromExcel(event.target.files[0])
    dispatch(setTable({ rows, tableId: getActiveContentId() }))
    dispatch(changeLoaderVisibility({ show: false }))
  }

  const menuItems = [
    { label: "Add Item", Icon: AddItem, action: addSiblingRowHandler },
    { label: "Add Subitem", Icon: AddSubItem, action: addChildRowHandler },
    { label: "Copy", Icon: Copy, action: copyHandler },
    { label: "Paste", Icon: Paste, action: pasteHandler },
    { label: "Cut", Icon: Cut, action: cutHandler },
    { label: "Move Item Up", Icon: ItemUp, action: upRowHandler },
    { label: "Move Item Down", Icon: ItemDown, action: downRowHandler },
    { label: "Outdent Item", Icon: Outdent, action: outdentHandler },
    { label: "Indent Item", Icon: Indent, action: indentHandler },
    { label: "Undo", Icon: Undo, action: undoHandler },
    { label: "Redo", Icon: Redo, action: redoHandler },
    { label: "Levels", Icon: LevelIcon, action: toggleLevel, extra: showLevel && <Levels levelNum={countLevels} /> },
    {
      label: "Row Height",
      Icon: RowHeightLogo,
      customComponent: (
        <ClickAndChange initialValue={20} onChange={changeHeight}>
          <RowHeightLogo />
        </ClickAndChange>
      ),
    },
    { label: "Add Category", Icon: CategoryIcon, action: toggleCategories },
    {
      label: "Columns",
      type: "custom",
      firstChild: true,
      customComponent: (
        <ColumnsPopup tableId={tableId}>
          <Columns />
        </ColumnsPopup>
      ),
    },
    { label: "Import", type: "custom", Icon: Import, action: clickInput },
    { label: "Export", type: "custom", Icon: Export, action: () => setExportDataVisibility(true) },
  ]

  return (
    <Styled.Wrapper>
      {menuItems.map(({ label, Icon, action, extra, customComponent, type, firstChild }, index) =>
        type === "custom" ? (
          <MenuButton key={index} type={type} label={label} onClick={action} firstChild={firstChild}>
            {customComponent || (
              <>
                {extra}
                <Icon />
              </>
            )}
          </MenuButton>
        ) : (
          <ToolTip key={index} text={label}>
            <MenuButton label={label} onClick={action}>
              {customComponent || (
                <>
                  {extra}
                  <Icon />
                </>
              )}
            </MenuButton>
          </ToolTip>
        )
      )}

      {exportDataVisibility && (
        <Popup onClose={() => setExportDataVisibility(false)}>
          <ExportTable />
        </Popup>
      )}

      {showCategories && (
        <Popup onClose={() => setShowCategories(false)}>
          <SelectCategory tableId={tableId} />
        </Popup>
      )}

      <input ref={inputRef} type="file" onChange={uploadFile} style={{ display: "none" }} />
    </Styled.Wrapper>
  )
}
