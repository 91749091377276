export enum CellTextAlign {
  START = "start",
  CENTER = "center",
  END = "end",
}

export enum ColumnIds {
  ID = "id",
  PARENT_INDEX = "parentIndex",
  POSITION = "position",
  CODE = "code",
  ITEM_NAME = "itemName",
  CATEGORY = "category",
  UNIT = "unit",
  QUANTITY = "quantity",
  QUANTITY_ABSOLUT = "quantityAbsolut",
  CATEGORY_MATERIAL_UNIT = "category_material_unit",
  CATEGORY_MATERIAL_TOTAL = "category_material_total",
  CATEGORY_LABOR_UNIT = "category_labor_unit",
  CATEGORY_LABOR_TOTAL = "category_labor_total",
  CATEGORY_EQUIPMENT_UNIT = "category_equipment_unit",
  CATEGORY_EQUIPMENT_TOTAL = "category_equipment_total",
  UNIT_COST = "unitCost",
  TOTAL_COST = "totalCost",
}

export enum ColumnStatuses {
  INITIAL = "INITIAL",
  DYNAMIC = "DYNAMIC",
  CUSTOM = "CUSTOM",
}

export enum AddRowOption {
  CHILD = "child",
  SIBLING_BELOW = "sibling_below",
  SIBLING_ABOVE = "sibling_above",
}
