import { useEffect, RefObject } from "react"
import { throttle } from "lodash"
import { handleArrowKey, isArrowKey } from "../../app/listeners"

type Props = {
  eventTargetRef: RefObject<HTMLDivElement>
  tableId: string
  rowIndex: number
  colIndex: number
}

export const useArrowKeyListeners = ({ eventTargetRef, tableId, rowIndex, colIndex }: Props) => {
  useEffect(() => {
    const cell = document.querySelector(`#cell-${tableId}-${rowIndex}-${colIndex}`)

    if (cell?.parentNode?.children[1] === cell) {
      eventTargetRef.current.parentElement?.scrollTo({
        left: 0,
        behavior: "instant",
      })
    } else {
      cell?.scrollIntoView({
        behavior: "instant",
        block: "nearest",
        inline: "nearest",
      })
    }
  }, [tableId, rowIndex, colIndex, eventTargetRef])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const throttled = throttle(handleArrowKey, 200)

    document.addEventListener(
      "keydown",
      (e) => {
        if (!isArrowKey(e.key)) return

        e.preventDefault()

        if (e.repeat) {
          throttled(e.key)
        } else {
          handleArrowKey(e.key)
        }
      },
      { signal }
    )

    return () => {
      controller.abort()
    }
  }, [eventTargetRef])
}
