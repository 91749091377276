import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { LoaderState } from "./types"
import { RootState } from "src/app/store"

const initialState: LoaderState = {
  show: false,
}

const loaderSlice = createSlice({
  name: "loader",
  initialState: initialState,
  reducers: {
    changeLoaderVisibility(state, action: PayloadAction<{ show: boolean }>) {
      state.show = action.payload.show
    },
  },
})

export function isLoaderVisible(state: RootState) {
  return state
}

export function showLoaderEl(state: RootState) {
  return state.loader.show
}

export const { changeLoaderVisibility } = loaderSlice.actions

export default loaderSlice.reducer
