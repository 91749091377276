import { CellTextAlign, ColumnIds, ColumnStatuses } from "./enums"
import { Column } from "./types"

export const defaultColumns: Array<Column> = [
  {
    id: ColumnIds.ID,
    title: "id",
    type: "number",
    defaultValue: { v: 1, e: 0 },
    show: false,
    editable: false,
    width: 0,
    align: CellTextAlign.START,
    status: ColumnStatuses.INITIAL,
  },
  {
    id: ColumnIds.PARENT_INDEX,
    title: "parent index",
    defaultValue: { v: -1, e: 0 },
    type: "number",
    show: false,
    editable: false,
    width: 0,
    align: CellTextAlign.START,
    status: ColumnStatuses.INITIAL,
  },
  {
    id: ColumnIds.POSITION,
    title: "Position",
    type: "text",
    defaultValue: { v: "1", e: 0 },
    show: true,
    editable: false,
    width: 100,
    align: CellTextAlign.START,
    status: ColumnStatuses.INITIAL,
  },
  {
    id: ColumnIds.CODE,
    title: "Code",
    type: "text",
    defaultValue: { v: "", e: 0 },
    show: true,
    editable: true,
    width: 100,
    align: CellTextAlign.START,
    status: ColumnStatuses.INITIAL,
  },
  {
    id: ColumnIds.ITEM_NAME,
    title: "Item Name",
    type: "text",
    defaultValue: { v: "", e: 0 },
    show: true,
    editable: true,
    expandable: true,
    width: 320,
    align: CellTextAlign.START,
    status: ColumnStatuses.INITIAL,
  },
  {
    id: ColumnIds.CATEGORY,
    title: "Category",
    type: "text",
    defaultValue: { v: "", e: 0 },
    show: true,
    editable: true,
    width: 100,
    align: CellTextAlign.CENTER,
    status: ColumnStatuses.INITIAL,
  },
  {
    id: ColumnIds.UNIT,
    title: "Unit",
    type: "text",
    defaultValue: { v: "", e: 0 },
    show: true,
    editable: true,
    width: 100,
    align: CellTextAlign.CENTER,
    status: ColumnStatuses.INITIAL,
  },
  {
    id: ColumnIds.QUANTITY,
    title: "Quantity",
    type: "number",
    defaultValue: { v: null, e: 0 },
    show: true,
    editable: true,
    width: 100,
    align: CellTextAlign.END,
    status: ColumnStatuses.INITIAL,
  },
  {
    id: ColumnIds.QUANTITY_ABSOLUT,
    title: "Quantity absolut",
    type: "number",
    defaultValue: { v: 1, e: 0 },
    show: true,
    editable: true,
    width: 100,
    align: CellTextAlign.END,
    status: ColumnStatuses.INITIAL,
  },
  {
    id: ColumnIds.CATEGORY_MATERIAL_UNIT,
    title: "Material Unit Cost",
    type: "number",
    defaultValue: { v: null, e: 0 },
    show: true,
    editable: true,
    width: 100,
    align: CellTextAlign.END,
    status: ColumnStatuses.DYNAMIC,
  },
  {
    id: ColumnIds.CATEGORY_MATERIAL_TOTAL,
    title: "Material Total Cost",
    type: "number",
    defaultValue: { v: null, e: 0 },
    show: true,
    editable: true,
    width: 100,
    align: CellTextAlign.END,
    status: ColumnStatuses.DYNAMIC,
  },
  {
    id: ColumnIds.CATEGORY_LABOR_UNIT,
    title: "Labor Unit Cost",
    type: "number",
    defaultValue: { v: null, e: 0 },
    show: true,
    editable: true,
    width: 100,
    align: CellTextAlign.END,
    status: ColumnStatuses.DYNAMIC,
  },
  {
    id: ColumnIds.CATEGORY_LABOR_TOTAL,
    title: "Labor Total Cost",
    type: "number",
    defaultValue: { v: null, e: 0 },
    show: true,
    editable: true,
    width: 100,
    align: CellTextAlign.END,
    status: ColumnStatuses.DYNAMIC,
  },
  {
    id: ColumnIds.CATEGORY_EQUIPMENT_UNIT,
    title: "Equipment Unit Cost",
    type: "number",
    defaultValue: { v: null, e: 0 },
    show: true,
    editable: true,
    width: 100,
    align: CellTextAlign.END,
    status: ColumnStatuses.DYNAMIC,
  },
  {
    id: ColumnIds.CATEGORY_EQUIPMENT_TOTAL,
    title: "Equipment Total Cost",
    type: "number",
    defaultValue: { v: null, e: 0 },
    show: true,
    editable: true,
    width: 100,
    align: CellTextAlign.END,
    status: ColumnStatuses.DYNAMIC,
  },
  {
    id: ColumnIds.UNIT_COST,
    title: "Unit Cost",
    type: "number",
    defaultValue: { v: null, e: 0 },
    show: true,
    editable: true,
    width: 100,
    align: CellTextAlign.END,
    status: ColumnStatuses.INITIAL,
  },
  {
    id: ColumnIds.TOTAL_COST,
    title: "Total Cost",
    type: "number",
    defaultValue: { v: null, e: 0 },
    show: true,
    editable: false,
    width: 100,
    align: CellTextAlign.END,
    status: ColumnStatuses.INITIAL,
  },
]
